<template>
    <div class="aktuelles">
        <AlleArtikel :sub="true" title="Aktuelles" desc="Hier finden sie aktuelle Meldungen rund um die Spielgruppe Gigampfi in Hochdorf." />
    </div>
</template>

<script>
// @ is an alias to /src
import AlleArtikel from '@/components/AlleArtikel.vue'

export default {
    name: 'Aktuelles',
    components: {
        AlleArtikel
    }
}
</script>
